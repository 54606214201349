<template>
    <div class="culture">
        <Top :img="'/gsmm/api/v1/'+columnList.img_info_list[0].url" :ch="ch" />
		<Title :ch="ch" :en="en" />
		<div class="content">
			{{columnList.description}}
		</div>
       <div class="center">
         <Menu  :menuList="columnList.son_tree_column" class="menu" />
<!--		    <div class="img">-->
<!--				<Background :style="{'width':'calc(100% + 20px)','margin': '10px 0 -15px -10px'}" />-->
<!--			</div>-->
<!--        <List :list="informationList"  :id="15"  />-->
<!--         <Pagination :page="form.pageNum" :pageSize="form.pageSize" :total="informationTotal" @handleList="handleList" />-->
         <router-view/>
     </div>

    </div>
</template>

<script>
import Top from '../components/top.vue'
import Title from '../components/title.vue'
import List from '../components/list.vue'
import Pagination from '../components/pagination.vue'
import Background from '../components/background.vue'
import { mapActions, mapState } from 'vuex'
import Menu from '../components/menu.vue'
export default {
    data() {
        return {

            ch:'乡村振兴',
			en:'RURAL VITALIZATION',
			form:{
				type:'PARK',
				pageNum:1,
				pageSize:6
			}
        };
    },
    components:{
        Top,
		List,
        Pagination,
		Title,
		Background,
      Menu
    },
	computed:{
...mapState(['columnList','informationList','informationTotal'])
	},
    created() {

    },
    mounted() {
		this.handleColumn(15)
		this.handelinformation1(this.form)
    },
    methods: {
		...mapActions(['handleColumn','handelinformation1']),
			handleList(e){
			this.form.pageNum=e
			this.handelinformation1(this.form)
		}
    }
};
</script>

<style scoped lang='less'>
.culture{
    width:100%;
	background-image: url('../../assets/bg.png');
	padding-bottom: 50px;
}
.content{
	padding: 20px 30%;
}
.center{
    padding:0 20%;
	width:100%;
	.img{
		// padding:0 20%;
	   text-align:center;
		img{
			width:100%
		}
	}
	.el-carousel{
		text-align: center;
		/* width: 1000px; */
		margin-top: 50px;
	}
}
</style>
